import { gql } from "@apollo/client";

export const MUTATION_CREATE_CUSTOMER = gql`
  mutation CreateCustomer($customer: CustomerInput) {
    createCustomer(customer: $customer) {
      customer {
        uuid
        first
        last
        phoneNumber
        email
        taxId
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_CREATE_PAYMENT_METHOD = gql`
  mutation CreateStripePaymentMethod(
    $stripePaymentMethod: StripePaymentMethodInput
  ) {
    createStripePaymentMethod(stripePaymentMethod: $stripePaymentMethod) {
      stripePaymentMethod {
        id
        paymentType
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($subscription: SubscriptionInput) {
    createSubscription(subscription: $subscription) {
      result {
        subscription {
          uuid
        }
        invoiceId
        unpaid
        stripeScaSecret
        requiresSca
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_CREATE_SUBSCRIPTION_V2 = gql`
  mutation CreateSubscriptionV2($subscription: SubscriptionInput) {
    createSubscriptionV2(subscription: $subscription) {
      result {
        subscription {
          uuid
        }
        stripeScaSecret
        requiresSca
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_ACTIVATE_SUBSCRIPTION = gql`
  mutation activateSubscription($subscriptionUUID: ID) {
    activateSubscription(subscriptionUUID: $subscriptionUUID) {
      success
    }
  }
`;

export const MUTATION_PAY_STRIPE_INVOICE = gql`
  mutation payStripeInvoice($invoiceId: ID) {
    payStripeInvoice(invoiceId: $invoiceId) {
      status
      error {
        message
      }
    }
  }
`;

export const MUTATION_UPDATE_STRIPE_PAYMENT_METHOD = gql`
  mutation UpdateStripePaymentMethodOnSubscription(
    $subscriptionUuid: UUID
    $stripePaymentMethodId: String
  ) {
    updateStripePaymentMethodOnSubscription(
      subscriptionUuid: $subscriptionUuid
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      result {
        id
        paymentMethod {
          id
          paymentType
          paymentVisibleIdentifier
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_VOID_STRIPE_INVOICE = gql`
  mutation voidStripeInvoice($invoiceId: ID, $subscriptionUuid: UUID) {
    voidStripeInvoice(
      invoiceId: $invoiceId
      subscriptionUuid: $subscriptionUuid
    ) {
      status
    }
  }
`;

export const MUTATION_MARK_UNCOLLECTIBLE_STRIPE_INVOICE = gql`
  mutation markUncollectibleStripeInvoice(
    $invoiceId: ID
    $subscriptionUuid: UUID
  ) {
    markUncollectibleStripeInvoice(
      invoiceId: $invoiceId
      subscriptionUuid: $subscriptionUuid
    ) {
      status
    }
  }
`;

export const MUTATION_CREDIT_NOTE_STRIPE_INVOICE = gql`
  mutation creditNoteStripeInvoice($invoiceId: ID) {
    creditNoteStripeInvoice(invoiceId: $invoiceId) {
      status
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($customer: CustomerUpdateInput, $uuid: UUID) {
    updateCustomer(customer: $customer, uuid: $uuid) {
      customer {
        uuid
        first
        last
        taxId
        email
        phoneNumber
        notes
        referralSource {
          uuid
        }
        address {
          road
          city
          county
          postcode
          houseNumber
        }
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_CREATE_CHECK_IN = gql`
  mutation CreateCheckin($checkIn: CheckInInput) {
    createCheckin(checkIn: $checkIn) {
      result {
        checkin {
          uuid
        }
        invoiceUuid
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_UPDATE_CHECKIN = gql`
  mutation updateCheckIn($checkin: CheckInUpdateInput, $guid: UUID) {
    updateCheckin(checkin: $checkin, guid: $guid) {
      result {
        checkin {
          uuid
          date
          time
          productZones
          notes
        }
        invoiceUuid
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_UPDATE_PAYMENT_INVOICE = gql`
  mutation updatePaymentInvoice(
    $paymentStatus: CreationStatusType!
    $paymentCode: String!
    $checkinUuid: UUID
  ) {
    updatePaymentInvoice(
      paymentStatus: $paymentStatus
      paymentCode: $paymentCode
      checkinUuid: $checkinUuid
    ) {
      invoice {
        uuid
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_UPDATE_STATE_CHECKIN = gql`
  mutation updateStateCheckIn(
    $checkInStatus: CreationStatusType!
    $guid: UUID
  ) {
    updateStateCheckin(checkInStatus: $checkInStatus, guid: $guid) {
      checkin {
        uuid
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_DELETE_CHECKIN = gql`
  mutation deleteCheckIn($guid: UUID) {
    deleteCheckin(guid: $guid) {
      checkin {
        uuid
        enabled
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription(
    $subscription: SubscriptionUpdateInput
    $guid: UUID
  ) {
    updateSubscription(subscription: $subscription, guid: $guid) {
      subscription {
        uuid
        startDate
        state
        zonesQuantity
        notes
        scheduledDate
        customer {
          uuid
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_CREATE_SETUP_INTENT = gql`
  mutation createStripeSetupIntent($customerUuid: UUID) {
    createStripeSetupIntent(customerUuid: $customerUuid) {
      clientSecret
      error {
        message
        code
      }
    }
  }
`;

export const MUTATION_UPDATE_USER_SETTING = gql`
  mutation updateUserSetting($userSettingInput: UserSettingInput) {
    updateUserSetting(userSettingInput: $userSettingInput) {
      userSetting {
        uuid
        currentLocation {
          uuid
          name
        }
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_CANCEL_SUBSCRIPTION_SCHEDULED = gql`
  mutation CancelScheduledSubscription($guid: UUID) {
    cancelScheduledSubscription(guid: $guid) {
      scheduledSubscriptionsQueueElement {
        uuid
        status
        scheduledDate
        subscription {
          uuid
          state
          cancelDate
          scheduledDate
        }
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_UPDATE_CREDIT = gql`
  mutation updateCredit($credit: creditInput, $guid: UUID) {
    updateCredit(credit: $credit, guid: $guid) {
      credit {
        uuid
        startDate
        endDate
        sessionQuantity
        notes
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_CREATE_CREDIT = gql`
  mutation CreateCredit($credit: creditInput) {
    createCredit(credit: $credit) {
      credit {
        uuid
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_DELETE_CREDIT = gql`
  mutation deleteCredit($credit: creditInput, $guid: UUID) {
    updateCredit(credit: $credit, guid: $guid) {
      credit {
        uuid
        canceledDate
        state
      }
      error {
        message
        code
      }
    }
  }
`;
// Auth
export const MUTATION_TOKEN_AUTH = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      payload
      refreshExpiresIn
    }
  }
`;

export const MUTATION_TOKEN_DELETE = gql`
  mutation deleteToken {
    deleteTokenCookie {
      deleted
    }
  }
`;

export const MUTATION_UPDATE_LEAD = gql`
  mutation UpdateLead(
    $uuid: UUID!
    $email: String
    $fullName: String
    $phoneNumber: String
    $locationUuid: UUID
  ) {
    updateLead(
      uuid: $uuid
      email: $email
      fullName: $fullName
      phoneNumber: $phoneNumber
      locationUuid: $locationUuid
    ) {
      lead {
        uuid
        email
        fullName
        phoneNumber
        location {
          uuid
          name
        }
      }
      error {
        message
      }
    }
  }
`;
export const MUTATION_AUTOASSIGN_LEAD = gql`
  mutation updateLead($uuid: UUID!) {
    updateLead(uuid: $uuid, ownerAutoassign: true) {
      lead {
        uuid
        email
        fullName
        phoneNumber
        location {
          uuid
          name
        }
      }
      error {
        message
      }
    }
  }
`;

export const MUTATION_CREATE_LEAD_ACTIVITY = gql`
  mutation createLeadActivity(
    $lead_uuid: UUID!
    $activity_type: LeadActivityTypeEnum!
    $notes: String
  ) {
    createLeadActivity(
      leadUuid: $lead_uuid
      activityType: $activity_type
      notes: $notes
    ) {
      leadActivity {
        uuid
        activityType
        notes
        payload
        lead {
          uuid
        }
        owner {
          id
          username
        }
        enabled
      }
      error {
        message
      }
    }
  }
`;

export const MUTATION_DELETE_LEAD_ACTIVITY = gql`
  mutation deleteLeadActivity($uuid: UUID!) {
    deleteLeadActivity(uuid: $uuid) {
      leadActivity {
        uuid
        enabled
      }
      error {
        message
      }
    }
  }
`;

export const MUTATION_EDIT_LEAD_ACTIVITY = gql`
  mutation editLeadActivity(
    $uuid: UUID!
    $notes: String
    $activity_type: LeadActivityTypeEnum
  ) {
    editLeadActivity(uuid: $uuid, notes: $notes, activityType: $activity_type) {
      leadActivity {
        uuid
        notes
      }
      error {
        message
      }
    }
  }
`;

export const MUTATION_UPDATE_EXPIRED_INVOICE = gql`
  mutation updateExpiredInvoice($expired: Boolean!, $guid: UUID) {
    updateExpiredInvoice(expired: $expired, guid: $guid) {
      invoice {
        uuid
        expired
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_UPDATE_PREPAID_PACKAGE = gql`
  mutation updatePrepaidPackage(
    $prepaidPackage: PrepaidPackageUpdate
    $guid: UUID
  ) {
    updatePrepaidPackage(prepaidPackage: $prepaidPackage, guid: $guid) {
      prepaidPackage {
        uuid
        startDate
        endDate
        sessionQuantity
        notes
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_DELETE_PREPAID_PACKAGE = gql`
  mutation deletePrepaidPackage(
    $prepaidPackage: PrepaidPackageUpdate
    $guid: UUID
  ) {
    updatePrepaidPackage(prepaidPackage: $prepaidPackage, guid: $guid) {
      prepaidPackage {
        uuid
        canceledDate
        state
      }
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_CREDIT_NOTE_STRIPE_CHARGE_REFUNDED = gql`
  mutation creditNoteStripeChargeRefunded(
    $receiptUuid: UUID!
    $amountRefunded: Decimal
  ) {
    creditNoteStripeChargeRefunded(
      receiptUuid: $receiptUuid
      amountRefunded: $amountRefunded
    ) {
      status
      error {
        message
        code
      }
    }
  }
`;
export const MUTATION_CREATE_INVOICE_FROM_RECEIPT = gql`
  mutation createInvoiceFromReceipt($receipt_uuid: UUID) {
    createInvoiceFromReceipt(receiptUuid: $receipt_uuid) {
      invoice {
        uuid
        progressiveNumber
        sector
      }
      error {
        message
        code
      }
    }
  }
`;
