import { config, envList, getEnv } from "../config";

import { COMUNI } from "../dataproc/lista-comuni";
import CodiceFiscale from "codice-fiscale-js";
import { PROVINCE } from "../dataproc/lista-province";
import constants from "../constants";

// const nodeCrypto = require("crypto");
export const forceRedirect = (url) => {
  // Forces a redirect to this URL making a new HTTP request
  window.location.href = url;
};

// Generates uuid->id type policies for the Apollo client
export const customIdTypePolicies = (typeNames, keyField = "uuid") => {
  const output = {};
  typeNames.forEach((name) => {
    output[name] = {
      keyFields: [keyField],
    };
  });
  return output;
};

export const makePrefixer = (prefix) => {
  return (name) => `${prefix}${name}`;
};

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export const extractPrefixedFields = (fields, prefix) => {
  const prefixLength = prefix.length;
  const filtered = Object.keys(fields)
    .filter((key) => {
      return key.startsWith(prefix);
    })
    .reduce((obj, key) => {
      obj[key.slice(prefixLength)] = fields[key];
      return obj;
    }, {});

  if (isEmptyObject(filtered)) {
    return null;
  }

  return filtered;
};

export function mapFormDataToCustomer(formData) {
  const customerData = removeEmptyFields(
    objectSubset(formData, [
      "first",
      "last",
      "taxId",
      "phoneNumber",
      "email",
      "stripePaymentMethod",
      "referralSource",
      "notes",
    ])
  );
  const address = removeEmptyFields(
    objectSubset(formData, [
      "road",
      "city",
      "houseNumber",
      "postcode",
      "county",
    ])
  );
  if (!isEmptyObject(address)) {
    customerData.address = address;
  }

  return customerData;
}

export function objectSubset(obj, keyList = []) {
  const filteredObject = Object.fromEntries(
    Object.entries(obj).filter(([key]) => keyList.includes(key))
  );
  return filteredObject;
}

export function removeEmptyFields(obj) {
  const filteredObject = Object.fromEntries(
    Object.entries(obj).filter((key) => {
      return !!key[1];
    })
  );
  return filteredObject;
}

// Node Polyfill
// https://github.com/KenanY/get-random-values/blob/master/index.js
// function getRandomValue(buf) {
//   if (nodeCrypto?.randomBytes) {
//     var bytes = nodeCrypto.randomBytes(buf.length);
//     buf.set(bytes);
//     return buf;
//   } else if (window?.crypto) {
//     return crypto.getRandomValues(buf);
//   } else {
//     throw new Error("No crypto getRandomValues available");
//   }
// }

// https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
// function uuid4() {
//   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
//     (c ^ (getRandomValue(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
//   );
// }
// Created using ChatGPT
function uuid4_v2() {
  // Generate 16 random bytes
  let bytes = [];
  for (let i = 0; i < 16; i++) {
    bytes.push(Math.floor(Math.random() * 256));
  }

  // Set the version to 4
  bytes[6] = (bytes[6] & 0x0f) | 0x40;

  // Set the variant to RFC4122
  bytes[8] = (bytes[8] & 0x3f) | 0x80;

  // Convert the bytes to hexadecimal strings
  let hex = [];
  for (let i = 0; i < bytes.length; i++) {
    hex.push(bytes[i].toString(16).padStart(2, "0"));
  }

  // Format the UUID as xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
  let uuid =
    hex.slice(0, 4).join("") +
    "-" +
    hex.slice(4, 6).join("") +
    "-" +
    hex.slice(6, 8).join("") +
    "-" +
    hex.slice(8, 10).join("") +
    "-" +
    hex.slice(10, 16).join("");

  return uuid;
}

export function generateIdempotencyKey() {
  const idempotencyKey = uuid4_v2();
  return idempotencyKey;
}
export function formatDateIso(inputDate) {
  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const returnValue = dateTimeFormat.formatToParts(inputDate);
  const stringDate = `${returnValue[4].value}-${returnValue[0].value}-${returnValue[2].value}`;
  return stringDate;
}

export function formatTimeIso(inputDate) {
  let parsedInputDate = inputDate;
  if (typeof inputDate == "string") {
    const timeArray = inputDate.split(":");
    parsedInputDate = new Date();
    parsedInputDate.setHours(parseInt(timeArray[0]));
    parsedInputDate.setMinutes(parseInt(timeArray[1]));
    parsedInputDate.setSeconds(0);
    parsedInputDate.setMilliseconds(0);
  }
  try {
    const stringTime = parsedInputDate.toISOString().substr(11);
    return stringTime;
  } catch (err) {
    const errorMessage = `Error formatting time ISO string for inputDate "${inputDate}": ${err.message}`;
    const newError = new Error(errorMessage);
    newError.previousError = err;
    throw newError;
  }
}

export function formatLocalTime(inputDate) {
  let parsedInputDate = inputDate;

  if (typeof inputDate == "string") {
    // Loads the UTC string into a Date object
    const timeArray = inputDate.split(":").map((item) => {
      return parseInt(item);
    });
    parsedInputDate = new Date();
    parsedInputDate.setUTCHours(timeArray[0], timeArray[1], timeArray[2]);
  }

  const m = parsedInputDate.getMinutes();
  const h = parsedInputDate.getHours();
  const paddedMinutes = (m < 10 ? "0" : "") + m;
  const paddedHours = (h < 10 ? "0" : "") + h;

  return `${paddedHours}:${paddedMinutes}`;
}
export function isEmpty(obj) {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export function isProd() {
  const env = getEnv();
  return env === envList.PRODUCTION;
}

export function isDev() {
  const env = getEnv();
  return env !== envList.DEVELOPMENT;
}

export function loadCheckinList(checkInTypeList, typeCheckIns) {
  /*   
  loadCheckinList
    This function is used to define the content of the "subscription" 
    and "checkinFundingSource" fields to be written on the record of the Check-In array.
    If a subscription has been chosen, the "checkinFundingSource" field must 
    be equal to constants.CHECKIN_FUNDING_SUBSCRIPTION. 
    If a credit has been chosen, the "checkinFundingSource" field must 
    be equal to constants.CHECKIN_FUNDING_CREDIT. 
    Otherwise, the "subscription" field and "credit" field must 
    be equal to null and the "checkinFundingSource" field with the chosen value.
    Also for foundigSouce indicates whether it is a gift or free trial.
     */
  //var k;
  let checkinList = [];
  let subscription = null;
  let checkinFundingSource = null;
  let checkinFundingSourceForControl = false;
  let credit = null;
  let prepaidPackage = null;
  for (const checkingType in checkInTypeList) {
    let checkInTypeK = checkInTypeList[checkingType];
    if (checkInTypeK.uuid === typeCheckIns) {
      switch (checkInTypeK.type) {
        case constants.TYPE_CHECKINLIST_SUBSCRIPTION:
          subscription = checkInTypeK.uuid;
          checkinFundingSource = constants.CHECKIN_FUNDING_SUBSCRIPTION;
          break;
        case constants.TYPE_CHECKINLIST_CREDIT:
          credit = checkInTypeK.uuid;
          checkinFundingSource = constants.CHECKIN_FUNDING_CREDIT;
          break;
        case constants.TYPE_CHECKINLIST_PREPAID_PACKAGE:
          prepaidPackage = checkInTypeK.uuid;
          checkinFundingSource = constants.CHECKIN_FUNDING_PREPAID_PACKAGE;
          break;
        case constants.TYPE_CHECKINLIST_FOUNDINGSOURCE:
          checkinFundingSource = checkInTypeK.uuid;
          switch (checkInTypeK.uuid) {
            case constants.FOUNDING_SOURCE_GIFT:
              checkinFundingSourceForControl = true;
              break;
            case constants.FOUNDING_SOURCE_FREE_TRIAL:
              checkinFundingSourceForControl = true;
              break;
            default:
              checkinFundingSourceForControl = false;
              break;
          }
          break;
        default:
      }
    }
  }
  checkinList.push({
    subscription: subscription,
    checkinFundingSource: checkinFundingSource,
    checkinFundingSourceForControl: checkinFundingSourceForControl,
    credit: credit,
    prepaidPackage: prepaidPackage,
  });
  return checkinList;
}

export function isPreviousCheckinForCadence(
  lastDateCheckIn,
  checkinCadence,
  todayDate = null
) {
  if (todayDate === null) {
    todayDate = new Date();
  }
  //The function checks that there are no more than one
  //checkin in the period indicated in the plan (checkinCadence)
  let checkInAvailability = true;
  //Last Date CheckIn
  let dateCheckin = new Date(lastDateCheckIn);
  dateCheckin.setHours(0, 0, 0, 0);
  //Date today
  let dateToday = new Date(todayDate);
  dateToday.setHours(0, 0, 0, 0);
  //There are subscriptions that offer multiple products on the same day
  //In this case, the control is not applied.
  if (dateCheckin.getTime() === dateToday.getTime()) return checkInAvailability;

  switch (checkinCadence.toUpperCase()) {
    case "WEEKLY":
      const lastSundayCheckIn = firstDayOfWeek(new Date(dateCheckin), 0);
      const lastSundayToDay = firstDayOfWeek(new Date(dateToday), 0);
      checkInAvailability =
        lastSundayCheckIn.getTime() === lastSundayToDay.getTime()
          ? false
          : true;
      break;
    case "MONTHLY":
      let firstDayCheckIn = new Date(
        dateCheckin.getFullYear(),
        dateCheckin.getMonth(),
        1
      );
      let firstDayToday = new Date(
        dateToday.getFullYear(),
        dateToday.getMonth(),
        1
      );
      checkInAvailability =
        firstDayCheckIn.getTime() === firstDayToday.getTime() ? false : true;
      break;
    default:
  }
  return checkInAvailability;
}

export function loadListCheckIn(customerCheckin) {
  let foundingSource;
  let listCheckIn = customerCheckin.map((s) => {
    foundingSource = !!s?.subscription?.plan?.shortName
      ? (foundingSource = s?.subscription?.plan?.shortName)
      : (foundingSource = s.checkinFundingSource?.name);
    const present = s.isPresent ? s?.product?.name : "Non Presentato";
    const checkinStatus =
      s.checkInStatus === constants.CHECK_IN_STATUS_TYPE_PENDING
        ? constants.STATUS_PENDING
        : null;
    return {
      uuid: s?.uuid,
      checkInDate: s.date,
      checkInPlanShortName: foundingSource,
      checkInProductName: present,
      checkInProductUuid: s?.product?.uuid,
      checkInStatus: checkinStatus,
    };
  });
  return listCheckIn;
}

export function loadListInvoice(invoiceList) {
  //Load array invoices STRIPE

  var arrayListInvoice = [];
  var j = 0;
  var invoicePaid;
  const INVOICE_PAID = "PAID";
  for (const value of invoiceList) {
    if (!!!value?.status) continue;

    //Load Invoice,
    j = j + 1;
    const invPaid = value?.amountPaid / 100;
    const isRefunded = value?.refundedAmount > 0;
    invoicePaid =
      value?.status === INVOICE_PAID
        ? "OK"
        : `${value?.errorMessage} (${value?.statusDetail});`;

    if (isRefunded) {
      invoicePaid =
        value?.amountPaid === value?.refundedAmount
          ? `${invoicePaid} - Rimborsato il ${value?.refundedDate}`
          : `${invoicePaid} - Rimborsato il ${value?.refundedDate} con ${
              value?.refundedAmount / 100
            }€`;
    }
    if (value.invoiceExpired) {
      invoicePaid = `${invoicePaid} - Scaduta`;
    }
    const item = `${j}) ${value?.dateEnd} -- ${invPaid} -- ${invoicePaid}`;
    const checkinCount = !!value.checkinCount ? value.checkinCount : null;
    const checkinCountNoPresent = !!value?.checkinCountNoPresent
      ? value?.checkinCountNoPresent
      : null;
    const invoice_checkin_uuid = !!value?.invoiceCheckin?.uuid
      ? value.invoiceCheckin.uuid
      : null;
    const invoice_checkin_date = !!value?.invoiceCheckin?.date
      ? value.invoiceCheckin.date
      : null;

    arrayListInvoice.push({
      dateEnd: value?.dateEnd,
      invPaid: invPaid,
      invoicePaid: invoicePaid,
      dateStart: value?.dateStart,
      statusDetail: value?.statusDetail,
      paidAt: value.paidAt,
      paymentIntentError: value.errorMessage,
      checkinCount: checkinCount,
      checkinCountNoPresent: checkinCountNoPresent,
      stripeInvoiceId: value.stripeInvoiceId,
      invoiceUuid: value.invoiceUuid,
      invoiceExpired: value.invoiceExpired,
      invoice_checkin_uuid: invoice_checkin_uuid,
      invoice_checkin_date: invoice_checkin_date,
      item: item,
      index: j,
    });
  }
  return arrayListInvoice;
}

// Returns the first error that is not none or undefined
export function mergeErrors(errors = []) {
  let e = null;
  for (e of errors) {
    if (!isEmpty(e)) {
      return e;
    }
  }
  return null;
}

export function controlTaxiId(taxCode, first, last) {
  if (taxCode.trim() === "") return "";

  if (taxCode.length === 11) {
    let isNumber = function isNumber(taxCode) {
      return typeof taxCode === "number" && isFinite(taxCode);
    };
    if (isNumber) return; //è una partita iva
  }

  var cf = new CodiceFiscale({
    name: first,
    surname: last,
    gender: "M",
    day: 1,
    month: 1,
    year: 1980,
    birthplace: "Napoli",
    birthplaceProvincia: "NA", // Optional
  });

  if (
    cf.code.toUpperCase().substr(0, 6) !== taxCode.toUpperCase().substr(0, 6)
  ) {
    return "Incoerenza fra Codice Fiscale e Nome-Cognome";
  }
  return "";
}

export function checkTaxiId(taxCode) {
  if (taxCode.trim() === "") return true;

  if (taxCode.length === 11) {
    let isNumber = function isNumber(taxCode) {
      return typeof taxCode === "number" && isFinite(taxCode);
    };
    if (isNumber) return true; //è una partita iva
  }
  //Check also for omocodia
  if (!CodiceFiscale.check(taxCode)) {
    return false;
  }

  return true;
}

export function capitalizeString(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  let str1 = splitStr.join(" ");
  let l1 = str1.indexOf("'");

  if (l1 !== -1) {
    str1 =
      str1.substr(0, l1) +
      str1.substr(l1, 2).toUpperCase() +
      str1.substr(l1 + 2, 99);
  }
  return str1;
}

export function allCounties() {
  let countyList = [];
  for (let key in PROVINCE) {
    countyList.push({
      code: key,
      name: PROVINCE[key],
    });
  }
  return countyList;
}

const CITY_EXISTING = 1;
const CITY_STATUS = 3;
const COUNTY_NAME = 1;
export function loadCitiesForCounty(currentCounty) {
  const filteredCitiesByCounty = COMUNI.filter(
    (city) =>
      city[CITY_STATUS] === CITY_EXISTING && city[COUNTY_NAME] === currentCounty
  ).map((city) => {
    return {
      keyCod: city[0],
      county: city[1],
      name: capitalizeString(city[2]),
    };
  });

  return filteredCitiesByCounty;
}

export const paymentMethod = {
  0: "Tutti",
  1: "Stripe",
  2: "Smallpay",
  3: "Pagodil",
  4: "SumUp",
  5: "Cofidis",
  6: "Altro",
};

export function loadPaymentMethod() {
  let arrayPaymentMethod = [];
  for (var key in paymentMethod) {
    arrayPaymentMethod.push({
      key,
      method: paymentMethod[key],
    });
  }
  return arrayPaymentMethod;
}

export function lookUpCadenceType(CadenceType) {
  const lookup = {
    WEEKLY: "Settimanale",
    MONTHLY: "Mensile",
    BI_WEEKLY: "Bimensile",
    QUARTERLY: "Trimestrale",
  };
  let result = lookup[CadenceType];

  result = !result ? CadenceType : result;
  return result;
}

export function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
  //The function takes a date as first parameter and the desired first day of the week
  //as second parameter (0 for Sunday, 1 for Monday, etc.).
  //Note: the hour, minutes and seconds are set to 0 to have the beginning of the day.
  //firstDayOfWeekIndex:
  //Sunday: 0
  //Monday: 1
  //Tuesday: 2
  //Wednesday:3
  //Thursday:4
  //Friday:5
  //Saturday: 6
  //Example call: const lastSunday = firstDayOfWeek(new Date("January 18, 2018 03:24:00"), 1);

  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff =
      dayOfWeek >= firstDayOfWeekIndex
        ? dayOfWeek - firstDayOfWeekIndex
        : 6 - dayOfWeek;

  firstDayOfWeek.setDate(dateObject.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek;
}

// Ensures the webpage is on HTTPS
export function ensureHTTPS() {
  const env = getEnv();
  if (env === envList.DEVELOPMENT) {
    return;
  }
  const location = window.location;

  if (location.protocol !== "https:") {
    location.replace(
      `https:${location.href.substring(location.protocol.length)}`
    );
  }
}
export const subscriptionItalianState = {
  ALL: "Tutti",
  ACTIVE: "Attivo",
  PENDING: "In Attesa",
  // PAUSED: "Sospeso",
  PAUSED: "In Pausa",
  SCHEDULED: "Schedulato",
  CANCELED: "Annullato",
  NEEDS_AUTH: "Richiede autorizzazione",
};

export const subscriptionItalianStatePaid = {
  ALL: "Tutti",
  PAID: "Pagato",
  UNPAID: "Insoluto",
};

export const invoiceItalianStatePaid = {
  PAID: "Pagata",
  UNPAID: "Insoluta",
};

export function loadSubscriptionItalianState() {
  let arraySubscriptionItalianState = [];
  for (const [key, state] of Object.entries(subscriptionItalianState)) {
    arraySubscriptionItalianState.push({
      key,
      state,
    });
  }
  return arraySubscriptionItalianState;
}

export const creditItalianState = {
  ALL: "Tutti",
  ACTIVE: "Attivo",
  CLOSED: "Chiuso",
  EXPIRED: "Scaduto",
  CANCELED: "Annullato",
};
export function loadCreditItalianState() {
  let arrayCreditItalianState = [];
  for (const [key, state] of Object.entries(creditItalianState)) {
    arrayCreditItalianState.push({
      key,
      state,
    });
  }
  return arrayCreditItalianState;
}

export const prepaidPackageTranslationState = {
  ALL: "Tutti",
  ACTIVE: "Attivo",
  CLOSED: "Chiuso",
  EXPIRED: "Scaduto",
  CANCELED: "Annullato",
};
export function loadPrepaidPackageTranslationState() {
  let arrayPrepaidPackageTranslationState = [];
  for (const [key, state] of Object.entries(prepaidPackageTranslationState)) {
    arrayPrepaidPackageTranslationState.push({
      key,
      state,
    });
  }
  return arrayPrepaidPackageTranslationState;
}

export function loadListProducts(
  customerPlanProducts,
  productsForFundingSource,
  radioValue,
  customerPrepaidPackageList = null
) {
  // Load select list products
  let mapProducts;
  if (
    [
      constants.TYPE_CHECKINLIST_SUBSCRIPTION,
      constants.TYPE_CHECKINLIST_CREDIT,
    ].includes(radioValue.radioType)
  ) {
    mapProducts = customerPlanProducts
      .filter(
        (customerPlanProducts) =>
          customerPlanProducts?.plan?.uuid === radioValue.planUuid
      )
      .map((customerPlanProducts) => {
        return {
          hasZones: customerPlanProducts?.product?.hasZones,
          name: customerPlanProducts?.product?.name,
          uuid: customerPlanProducts?.product?.uuid,
          consentLaser: customerPlanProducts?.product?.consentLaserIsRequired,
        };
      });
    return mapProducts;
  }
  if (
    [constants.TYPE_CHECKINLIST_PREPAID_PACKAGE].includes(radioValue.radioType)
  ) {
    mapProducts = customerPrepaidPackageList
      .filter(
        (customerPrepaidPackageList) =>
          customerPrepaidPackageList?.PrepaidPackage?.packagePlan?.uuid ===
          radioValue.planUuid
      )
      .map((customerPrepaidPackageList) => {
        return {
          hasZones:
            customerPrepaidPackageList?.PrepaidPackage?.packagePlan?.product
              ?.hasZones,
          name: customerPrepaidPackageList?.PrepaidPackage?.packagePlan?.product
            ?.name,
          uuid: customerPrepaidPackageList?.PrepaidPackage?.packagePlan?.product
            ?.uuid,
          consentLaser:
            customerPrepaidPackageList?.PrepaidPackage?.packagePlan?.product
              ?.consentLaserIsRequired,
        };
      });
  } else {
    mapProducts = productsForFundingSource
      .filter(
        (productsForFundingSource) =>
          productsForFundingSource?.checkinfundingsource?.uuid ===
          radioValue.radioUuid
      )
      .map((productsForFundingSource) => {
        return {
          hasZones: productsForFundingSource?.product?.hasZones,
          name: productsForFundingSource?.product?.name,
          uuid: productsForFundingSource?.product?.uuid,
          consentLaser:
            productsForFundingSource?.product?.consentLaserIsRequired,
        };
      });
  }
  return mapProducts;
}

export function diffDays(d1, d2) {
  var ndays;
  var tv1 = d1.valueOf(); // msec since 1970
  var tv2 = d2.valueOf();

  ndays = (tv2 - tv1) / 1000 / 86400;
  ndays = Math.round(ndays - 0.5);
  return ndays;
}

export function diffMinute(time1, time2) {
  const t1 = new Date(time1);
  const t2 = new Date(time2);
  const diffInMs = Math.abs(t2 - t1); // difference in milliseconds
  return Math.floor(diffInMs / (1000 * 60)); // difference in minutes;
}

export function toDayString(d) {
  // return data string format dd-mm-yyy
  const dateToday =
    d.getDate().toString().padStart(2, "0") +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    d.getFullYear();
  return dateToday;
}
export function isNumber(n) {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

export function getDateBirthFromTaxId(taxId) {
  let taxIdUpper = taxId.toUpperCase();
  const taxIdMonthMapping = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    H: 6,
    L: 7,
    M: 8,
    P: 9,
    R: 10,
    S: 11,
    T: 12,
  };

  let dateDob = null;
  if (taxIdUpper === null || taxIdUpper === "") return dateDob;
  let now = new Date();
  const year = now.getFullYear();
  now.setHours(0, 0, 0, 0);

  const dayTax = taxIdUpper.substring(9, 11);
  let dayDob;
  if (isNumber(dayTax)) {
    dayDob = parseInt(dayTax);
    if (dayDob > 40) dayDob = dayDob - 40;
  } else return dateDob;

  const yearTax = taxIdUpper.substring(6, 8);
  let yearDob = parseInt(yearTax) + 2000;
  if (yearDob > year - 1) yearDob = parseInt(yearTax) + 1900;

  const monthDob = taxIdMonthMapping[taxIdUpper.substring(8, 9)] - 1;

  const return_dob = new Date(yearDob, monthDob, dayDob);
  return return_dob;
}

export function getAge(dateOfBirth, dateToCalculate = new Date()) {
  const dob = new Date(dateOfBirth).getTime();
  const dateToCompare = new Date(dateToCalculate).getTime();
  const age = (dateToCompare - dob) / (365 * 24 * 60 * 60 * 1000);
  return Math.floor(age);
}
export function sumUpUrlConstruction(
  amount,
  customerEmail,
  customerPhoneNumber,
  title,
  checkInUuid
) {
  // 192.168.1.22:3000/customers/6b08a320-2cb8-46c3-a2c2-edad31b7fe7e?payment_success=true&smp-status=success&smp-tx-code=TEU2K2427C
  // localhost:3000/sumup/?payment_success=true&checkInUuid=fb3afbf2-2d98-4a8f-8254-b45032afb2a0&smp-status=success&smp-tx-code=TEU2K2427C
  const url = new URL("sumupmerchant://pay/1.0");
  const currentHost = config.SUMUP_CALLBACK_HOST;

  const sumUpCallbackSuccess = `http://${currentHost}/sumup/?payment_success=true&checkInUuid=${checkInUuid}`;
  const sumUpCallbackFail = `http://${currentHost}/sumup/?payment_success=false&checkInUuid=${checkInUuid}`;
  const params = {
    amount: amount,
    currency: "EUR",
    "affiliate-key": config.SUMUP_AFFILIATE_KEY,
    title: title,
    "receipt-email": customerEmail,
    callbacksuccess: sumUpCallbackSuccess,
    "receipt-mobilephone": customerPhoneNumber,
    callbackfail: sumUpCallbackFail,
  };
  for (const [key, value] of Object.entries(params)) {
    if (value !== null) url.searchParams.append(key, value);
  }

  return url.toString();
}
export function calculateDurationCheckIn(
  checkInStatus,
  checkInclosedDate,
  checkInTimestamp
) {
  // Check for old Check-In
  if (
    checkInStatus === constants.CHECK_IN_STATUS_TYPE_COMPLETED &&
    !checkInclosedDate
  )
    return null;

  const diffMinsOut =
    checkInStatus === constants.CHECK_IN_STATUS_TYPE_COMPLETED &&
    !!checkInclosedDate
      ? diffMinute(checkInTimestamp, checkInclosedDate)
      : diffMinute(checkInTimestamp, new Date());
  return diffMinsOut;
}
export function checkFeatureFlagRequirement(featureFlags, flagName) {
  const signatureFlag = featureFlags.find((flag) => flag.name === flagName);
  return signatureFlag ? signatureFlag.enabled : false;
}

export function isUserInGroup(userSetting, groupName) {
  /**
  Checks if a group name exists within the user's groups from a GraphQL query output.
 
  @param {object} userSetting - The output from the GraphQL query.
  @param {string} groupName - The name of the group to search for.
  @returns {boolean} True if the group name is found, false otherwise.

 */

  // Use find() to search for a group with a name matching varName
  const foundGroup = userSetting?.user?.groups.find(
    (group) => group.name === groupName
  );

  // If find() returns an object, it means a match was found
  return !!foundGroup;
}
