import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import PrepaidPackageUpdateForm from "../components/PrepaidPackageUpdateForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { MUTATION_DELETE_PREPAID_PACKAGE } from "../api/mutations";
import { MUTATION_UPDATE_PREPAID_PACKAGE } from "../api/mutations";
import { MUTATION_CREDIT_NOTE_STRIPE_CHARGE_REFUNDED } from "../api/mutations";
import { MUTATION_CREATE_INVOICE_FROM_RECEIPT } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_GET_PREPAID_PACKAGE } from "../api/queries";
import { getPrepaidPackageDetailUrl } from "../api/urls";
import { useParams } from "react-router-dom";
import { isProd } from "../api/utils";

function loadListCheckIn(checkInPrepaidPackage) {
  let foundingSource;
  let listCheckIn = checkInPrepaidPackage.map((s) => {
    foundingSource = !!s?.prepaidPackage?.packagePlan?.shortName
      ? (foundingSource = s?.prepaidPackage?.packagePlan?.shortName)
      : (foundingSource = s?.checkinFundingSource?.name);

    return {
      uuid: s?.uuid,
      checkInDate: s?.date,
      checkInPlanShortName: foundingSource,
      checkInProductName: s?.product?.name,
      checkInProductUuid: s?.product?.uuid,
    };
  });
  return listCheckIn;
}

function PrepaidPackageUpdatePage() {
  let { prepaidPackageUuid } = useParams();
  const [completed, setCompleted] = useState(false);
  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);

  const [
    updatePrepaidPackagetMutation,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(MUTATION_UPDATE_PREPAID_PACKAGE);

  const [
    deletePrepaidPackagetMutation,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(MUTATION_DELETE_PREPAID_PACKAGE);

  const [
    creditNoteStripeChargeRefunded,
    { loading: creditNoteLoading, error: creditNoteError },
  ] = useMutation(MUTATION_CREDIT_NOTE_STRIPE_CHARGE_REFUNDED, {
    onCompleted: () => {
      setCompleted(true);
    },
  });

  const [
    createInvoiceFromReceipt,
    { loading: createInvoiceLoading, error: createInvoiceError },
  ] = useMutation(MUTATION_CREATE_INVOICE_FROM_RECEIPT, {
    onCompleted: () => {
      setCompleted(true);
    },
  });

  const { loading, error, data } = useQuery(QUERY_GET_PREPAID_PACKAGE, {
    variables: {
      prepaidPackageuuid: prepaidPackageUuid,
    },
  });

  if (
    loading ||
    deleteLoading ||
    mutationLoading ||
    creditNoteLoading ||
    createInvoiceLoading
  ) {
    return <p>Loading..</p>;
  }

  if (error || mutationError || deleteError || createInvoiceError) {
    return <ErrorMessage errors={[error]} />;
  }

  const currentLocation = data?.userSetting?.currentLocation;
  const isSameLocation =
    currentLocation?.uuid === data?.getPrepaidPackage?.location?.uuid
      ? true
      : false;
  const counterCheckIn = data?.checkInCounter;
  const checkinList = loadListCheckIn(data?.checkInPrepaidPackage);
  let lastCheckin;
  if (checkinList.length > 0) {
    lastCheckin = checkinList[0].checkInDate;
  }

  let redirectContinueToList = null;
  if (!!shouldRedirectToList || !isSameLocation) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getPrepaidPackageDetailUrl(prepaidPackageUuid),
        }}
      />
    );
  }

  let redirectDeleteCompleted = null;

  if (!!deleteData) {
    redirectDeleteCompleted = (
      <Navigate
        to={{
          pathname: getPrepaidPackageDetailUrl(prepaidPackageUuid),
        }}
      />
    );
  }

  if (isProd()) {
    if (!!creditNoteError) {
      return <ErrorMessage errors={[creditNoteError]} />;
    }
  }

  return (
    <>
      <PrepaidPackageUpdateForm
        data={data}
        lastCheckin={lastCheckin}
        counterCheckIn={counterCheckIn}
        checkInNotPresentCounter={data?.checkInNotPresentCounter}
        deletePrepaidPackage={deletePrepaidPackagetMutation}
        updatePrepaidPackage={updatePrepaidPackagetMutation}
        creating={mutationLoading}
        createError={mutationError}
        deleting={deleteLoading}
        creditNoteStripeChargeRefunded={creditNoteStripeChargeRefunded}
        creditNoteLoading={creditNoteLoading}
        creditNoteError={creditNoteError}
        createInvoiceFromReceipt={createInvoiceFromReceipt}
        createInvoiceLoading={createInvoiceLoading}
        createInvoiceError={createInvoiceError}
        completed={completed}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
        userSetting={data?.userSetting}
      />
      {redirectDeleteCompleted}
      {redirectContinueToList}
    </>
  );
}

export default PrepaidPackageUpdatePage;
