import * as yup from "yup";
import React, { useEffect, useState } from "react";
import {
  formatDateIso,
  formatLocalTime,
  formatTimeIso,
  isEmpty,
  loadCheckinList,
  loadListProducts,
  sumUpUrlConstruction,
  checkFeatureFlagRequirement,
} from "../api/utils";
import {
  getCheckinDetailUrl,
  getCustomerUrl,
  getReceiptDetailUrl,
} from "../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "./ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import addBsArrowRight from "../components/AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "../components/DropDownHeaderView";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import StateIcon from "../components/StateIcon";
import styled from "@emotion/styled/macro";
import Badge from "react-bootstrap/Badge";

// Styles
const EmojiSpan = styled.span`
  font-size: 1.1 rem;
`;
const VALIDATION_MANDATORY_FIELD = "Campo obbligatorio";
const schema = yup.object().shape({
  date: yup
    .date()
    .max(new Date(), "La data non può essere nel futuro")
    .required(VALIDATION_MANDATORY_FIELD),
  time: yup.string().required(VALIDATION_MANDATORY_FIELD),
  worker: yup.string().required(VALIDATION_MANDATORY_FIELD),
  station: yup.string().required(VALIDATION_MANDATORY_FIELD),
  product: yup.string().required(VALIDATION_MANDATORY_FIELD),
  productZones: yup
    .number()
    .required(VALIDATION_MANDATORY_FIELD)
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
  typeCheckIns: yup.string().required(VALIDATION_MANDATORY_FIELD),
  mandatoryNotes: yup.number(),
  notes: yup.string().when("mandatoryNotes", (mandatoryNotes, schema) => {
    return schema.test({
      test: (notes) => !(notes === "" && mandatoryNotes === 1),
      message: "Nelle note occorre indicare il motivo",
    });
  }),
});

function ContinueModal({ onContinue, show }) {
  let btnContinua = (
    <Button variant="primary" onClick={onContinue}>
      Continua
    </Button>
  );
  let body = (
    <Modal.Body>Il nuovo Check-In è stato registrato correttamente.</Modal.Body>
  );
  if (!!show?.checkinUuid && !!show?.invoiceUuid) {
    btnContinua = (
      <Button variant="primary" href={getReceiptDetailUrl(show?.invoiceUuid)}>
        Visualizza Ricevuta
      </Button>
    );
    body = (
      <Modal.Body>
        <p>
          Il nuovo Check-In è stato registrato correttamente. <br></br>Il
          trattamento è a pagamento ed è stata emessa anche la ricevuta.
        </p>
      </Modal.Body>
    );
  }

  return (
    <Modal show={show.show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Registrazione Check-In</Modal.Title>
      </Modal.Header>
      {body}
      <Modal.Footer>{btnContinua}</Modal.Footer>
    </Modal>
  );
}

function extractZonesForSelected(subscriptionList) {
  const listSubscription = subscriptionList.find(
    (subscriptionList) => subscriptionList.checked === 1
  );
  return !!listSubscription ? listSubscription.zone : null;
}

function findCheckInDuplicate(checkinList, formDate, formProduct) {
  //Search if there is a checkin on the same date for the same product
  const checkinFound = checkinList.find(
    (checkin) =>
      checkin.checkInDate === formatDateIso(formDate) &&
      checkin.checkInProductUuid === formProduct
  );
  return !!checkinFound ? true : false;
}

function findHasZones(mapProducts, formProduct, formProductZones) {
  //Check if the chosen treatment foresees the application in zones
  const product = !!mapProducts.find(
    (products) => products.uuid === formProduct && products.hasZones
  );
  return product ? formProductZones : 1;
}

function findNumZones(mapProducts, attualProduct) {
  //Check if the chosen treatment foresees the application in zones
  const xx = !!mapProducts.find(
    (products) => products.uuid === attualProduct && products.hasZones
  );
  const constNum = xx
    ? Array.from({ length: 15 }, (_, i) => i + 1)
    : Array.from({ length: 1 }, (_, i) => i + 1);

  return constNum;
}
function findConsentLaserIsRequired(mapProducts, formProduct) {
  //Find out if your chosen treatment needs consent for laser treatment
  const consentLaserIsRequired = !!mapProducts.find(
    (products) => products.uuid === formProduct && products.consentLaser
  );
  return consentLaserIsRequired;
}

function findDuplicateCheckinOpen(
  listOpenCheckins,
  customerUuid,
  workerUuid,
  stationUuid
) {
  let msg;
  let check;
  if (!!listOpenCheckins) {
    //Check if there is an open check-in for the same customer
    check = !!listOpenCheckins.find(
      (checkin) => checkin.customer.uuid === customerUuid
    );
    if (check)
      msg = (
        <Alert variant="danger">Il cliente ha già un Check-In aperto.</Alert>
      );
    //Check if there is an open check-in for the same worker
    check = !!listOpenCheckins.find(
      (checkin) => checkin.worker.uuid === workerUuid
    );
    if (check)
      msg = (
        <Alert variant="danger">L'estetista ha già un Check-In aperto.</Alert>
      );
    //Check if there is an open check-in for the same station
    check = !!listOpenCheckins.find(
      (checkin) => checkin.station.uuid === stationUuid
    );
    if (check)
      msg = (
        <Alert variant="danger">
          Il box è già occupato per un altro Check-In.
        </Alert>
      );
  }
  return msg;
}

function CustomerCheckInForm({
  checkInTypeList,
  workers,
  stations,
  customer,
  productsForFundingSource,
  counterCheckIn,
  createCheckin,
  creating,
  createError,
  modalContinueClicked = () => {},
  checkinList,
  openCustomerCheckinsList,
  customerPlanProducts,
  initialState,
  productDefault,
  productsPrice,
  listOpenCheckins,
  featureFlags,
  customerPrepaidPackageList,
}) {
  let checkInUuid = null;
  let invoiceUuid = null;

  const isRequirementForSignatureContract = checkFeatureFlagRequirement(
    featureFlags,
    constants.FEATURE_FLAG_FOR_SIGNATURE_CONTRACT
  );

  const [showModal, setShowModal] = useState({
    show: false,
    checkinUuid: null,
    invoiceUuid: null,
  });
  const [radioValue, setRadioValue] = useState({
    radioUuid: initialState[0].radioUuid,
    radioType: initialState[0].radioType,
    planUuid: initialState[0].planUuid,
  });

  const [showInfo, setShowInfo] = useState(false);
  const [showInfoCredit, setShowInfoCredit] = useState(false);
  const [showCheckInDuplicate, setCheckInDuplicate] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  let [showAlertInvoice, setShowAlertInvoice] = useState(false);
  const [msgValue, setMsgValue] = useState(null);
  const [createSuccess, setCreateSuccess] = useState("");
  const [changeProduct, setChangeProduct] = useState(productDefault);
  const [showLaserInfo, setshowLaserInfo] = useState(false);
  const [showPrivacyInfo, setShowPrivacyInfo] = useState(
    !customer?.agreementPrivacyConsent
  );
  const [enableSubmitCheckin, setEnableSubmitCheckin] = useState(true);
  const [showSubscriptionPresent, setSubscriptionPresent] = useState(false);
  const [showTaxIdNotPresent, setshowTaxIdNotPresent] = useState(
    !customer?.taxId
  );

  const actualRadioValue = {
    radioUuid: radioValue.radioUuid,
    radioType: radioValue.radioType,
    planUuid: radioValue.planUuid,
  };

  // Load select list products
  const mapProducts = loadListProducts(
    customerPlanProducts,
    productsForFundingSource,
    actualRadioValue,
    customerPrepaidPackageList
  );

  useEffect(() => {
    function searchConsentLaserIsRequired(
      customer,
      mapProducts,
      productDefault
    ) {
      checkConsentLaserIsRequired(
        customer,
        mapProducts,
        productDefault,
        "info"
      );
      setshowLaserInfo(true);
    }

    if (!showInfo)
      searchCheckInAvailability(
        checkInTypeList,
        radioValue.planUuid,
        radioValue.radioUuid
      );
    if (!showLaserInfo)
      searchConsentLaserIsRequired(customer, mapProducts, changeProduct);
  }, [
    checkInTypeList,
    customer,
    mapProducts,
    productDefault,
    radioValue,
    showInfo,
    showLaserInfo,
    changeProduct,
    listOpenCheckins,
  ]);

  let checkedZonesQuantity = extractZonesForSelected(checkInTypeList);

  // Form hooks
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      date: formatDateIso(new Date()),
      time: formatLocalTime(new Date()),
      productZones: checkedZonesQuantity,
      product: changeProduct,
      present: true,
    },
  });

  let constNum = [];
  const alertNeedsPrivacy = (
    <Alert variant="info">
      <p>Manca il Consenso "Privacy".</p>
    </Alert>
  );

  if (!!changeProduct) {
    constNum = findNumZones(mapProducts, changeProduct);
    if (getValues("product") !== changeProduct) {
      setValue("product", changeProduct);
    }
  } else {
    // Set product default
    const productPlanDefault =
      mapProducts.length === 0
        ? constants.PRODUCT_HAIR_REMOVAL
        : mapProducts[0].uuid;
    setValue("product", productPlanDefault);
    constNum = findNumZones(mapProducts, productPlanDefault);
  }

  let groupShowingPrice = (
    <>
      <Col>
        <FormElement
          label="Prezzo"
          name="price"
          register={register}
          errors={errors}
          controlOptions={{ disabled: true }}
        ></FormElement>
      </Col>
      <Col>
        <FormElement
          label="Costo Totale"
          name="totalPrice"
          register={register}
          errors={errors}
          controlOptions={{ disabled: true }}
        ></FormElement>
      </Col>
    </>
  );

  function checkConsentLaserIsRequired(
    customer,
    mapProducts,
    productUuid,
    variant = "danger"
  ) {
    let result = false;
    let msg =
      "La registrazione non può avvenire perchè manca il Consenso al Trattamento Laser";
    if (variant === "info") msg = "Manca il Consenso al Trattamento Laser";
    if (!customer.agreementLaserTreatment) {
      if (findConsentLaserIsRequired(mapProducts, productUuid)) {
        result = true;
        setMsgValue(<Alert variant={variant}>{msg}</Alert>);
      }
    }
    return result;
  }

  function handleChangeRadio(s) {
    setRadioValue({
      radioUuid: s.uuid,
      radioType: s.type,
      planUuid: s.planUuid,
    });

    setShowInfo(false);
    setShowInfoCredit(false);
    setValue("present", true);
    //Removes any previous error reports
    clearErrors("notes");

    //Check if the notes are required or not
    const checkinList = loadCheckinList(checkInTypeList, s.uuid);
    if (checkinList[0].checkinFundingSourceForControl) {
      setValue("mandatoryNotes", constants.MANDATORY_NOTES);
    } else {
      setValue("mandatoryNotes", constants.NO_MANDATORY_NOTES);
    }
    const radioCheck = {
      radioUuid: s.uuid,
      radioType: s.type,
      planUuid: s.planUuid,
    };
    const productList = loadListProducts(
      customerPlanProducts,
      productsForFundingSource,
      radioCheck,
      customerPrepaidPackageList
    );

    if (productList.length === 0) return;

    const uuidProduct = getValues("product");
    const attualProduct = productList.find(
      (products) => products.uuid === uuidProduct
    );
    const newProductUuid = !!attualProduct
      ? attualProduct.uuid
      : productList[0].uuid;
    // Set product default
    setValue("product", newProductUuid);
    setChangeProduct(newProductUuid);

    constNum = findNumZones(productList, newProductUuid);
    const showFieldsPrice =
      checkinList[0].checkinFundingSource ===
      constants.CHECKIN_FUNDING_PAID_ACCESS
        ? true
        : false;

    setShowPrice(showFieldsPrice);
    setShowAlertInvoice(showFieldsPrice);
    if (showFieldsPrice)
      calculateTotalPrice(newProductUuid, null, showFieldsPrice);
    setMsgValue(null); //erase previus error
    checkConsentLaserIsRequired(customer, productList, newProductUuid, "info");
  }

  function searchCheckInAvailability(
    checkInTypeList,
    planUuid,
    subscriptionUuid
  ) {
    const subscriptionFound = checkInTypeList.find(
      (subscription) =>
        subscription.planUuid === planUuid &&
        subscription.uuid === subscriptionUuid &&
        subscription.type === constants.TYPE_CHECKINLIST_SUBSCRIPTION &&
        !subscription.checkInAvailability
    );

    if (!!subscriptionFound) {
      setShowInfo(true);
    }
    const creditFound = checkInTypeList.find(
      (credit) =>
        credit.planUuid === planUuid &&
        credit.uuid === subscriptionUuid &&
        credit.type === constants.TYPE_CHECKINLIST_CREDIT &&
        !credit.checkInAvailability
    );
    if (!!creditFound) {
      setShowInfoCredit(true);
    }
  }

  const onSubmit = async (formData) => {
    setMsgValue(null);
    setShowPrivacyInfo(false);
    setCheckInDuplicate(false);
    setSubscriptionPresent(false);
    setshowTaxIdNotPresent(false);
    //Search if there is a checkin on the same date for the same product
    if (findCheckInDuplicate(checkinList, formData.date, formData.product)) {
      setCheckInDuplicate(true);
      return;
    }
    // const index = findIndexByUuid(checkInTypeList, radioValue.radioUuid);
    const index = checkInTypeList.findIndex(
      (item) => item.uuid === radioValue.radioUuid
    );

    const listCheckin = loadCheckinList(checkInTypeList, radioValue.radioUuid);

    const subscription = listCheckin[0].subscription;
    const checkinfundingsource = listCheckin[0].checkinFundingSource;
    const credit = listCheckin[0].credit;
    const prepaidPackage = listCheckin[0].prepaidPackage;
    //Check if the chosen treatment foresees the application in zones
    const numZones = findHasZones(
      mapProducts,
      formData.product,
      formData.productZones
    );
    const present = formData.present === "false" ? false : true;
    // For a subscription with checkinPossible=false you can only do a Check-In for "not Present"
    if (!!subscription) {
      if (checkInTypeList[index].checkinPossible === false && present) {
        setSubscriptionPresent(true);
        return;
      }
    }
    if (
      !(
        checkinfundingsource === constants.CHECKIN_FUNDING_CONSULTANT ||
        present === false
      )
    ) {
      setMsgValue(null); //erase previus error
      const msg = findDuplicateCheckinOpen(
        listOpenCheckins,
        customer.uuid,
        formData.worker,
        formData.station
      );
      if (!!msg) {
        setMsgValue(msg);
        return;
      }
    }
    // Load the invoices table
    let invoices = [];
    let count = 0;
    if (!!subscription) {
      const foundCheckInTypeList = checkInTypeList.find(
        (obj) => obj.uuid === subscription
      );
      if (foundCheckInTypeList.checkinCadence === constants.QUARTERLY) {
        for (const invoiceIndex in foundCheckInTypeList?.invoices) {
          if (count >= 3) {
            break;
          }
          invoices[invoiceIndex] =
            foundCheckInTypeList?.invoices[invoiceIndex]?.uuid.toString();
          count++;
        }
      } else invoices[0] = foundCheckInTypeList?.invoices[0]?.uuid.toString();
    }

    const checkIn = {
      date: formatDateIso(formData.date),
      time: formatTimeIso(formData.time),
      customer: customer.uuid,
      subscription: subscription,
      product: formData.product,
      worker: formData.worker,
      station: formData.station,
      productZones: numZones,
      notes: formData.notes,
      checkinfundingsource: checkinfundingsource,
      credit: credit,
      isPresent: present,
      paymentStatus: showPrice ? constants.PAYMENT_STATUS_TYPE_PENDING : null,
      invoices: invoices,
      prepaidPackage: prepaidPackage,
    };
    setEnableSubmitCheckin(false);
    const result = await createCheckin({
      variables: {
        checkIn,
      },
    });
    if (!!result?.data?.createCheckin?.error) {
      setMsgValue(
        <Alert variant="danger">
          {result?.data?.createCheckin?.error?.message}
        </Alert>
      );
      return;
    } else {
      if (checkinfundingsource === constants.CHECKIN_FUNDING_PAID_ACCESS) {
        checkInUuid = result?.data?.createCheckin?.result?.checkin?.uuid;
        invoiceUuid = result?.data?.createCheckin?.result?.invoiceUuid;
      }
      setCreateSuccess(creatingSuccess);
      if (!present) {
        setShowModal({
          show: true,
          checkinUuid: checkInUuid,
          invoiceUuid: null,
        });
        return;
      }
      setShowAlertInvoice(false);

      if (showPrice) {
        const dictResult = calculateInvoicePrice();
        const title = !dictResult.hasZones
          ? `Cliente: ${customer.first} ${customer.last} - Trattamento: ${dictResult.productName}`
          : `Cliente: ${customer.first} ${customer.last} - Trattamento: ${dictResult.productName} - N. Zone: ${dictResult.zonesNum}`;
        const sumUpUrl = sumUpUrlConstruction(
          dictResult.priceTotal,
          customer.email,
          customer.phoneNumber,
          title,
          checkInUuid
        );
        window.location.href = sumUpUrl;
      } else
        setShowModal({
          show: true,
          checkinUuid: checkInUuid,
          invoiceUuid: invoiceUuid,
        });
    }
  };

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );

  let radioSub = null;
  if (checkInTypeList.length === 0) {
    radioSub = (
      <Alert key="nosub" variant="info">
        Non ci sono abbonamenti
      </Alert>
    );
  } else {
    radioSub = (
      <>
        <Form.Group controlId="typeCheckIns">
          {checkInTypeList.map((s) => {
            let label = s?.name;
            let disabled = false;
            const status = (
              <StateIcon nameIcon={s?.state} objType="Icon"></StateIcon>
            );
            let avi = null;
            let numRate = null;
            switch (s.type) {
              case constants.TYPE_CHECKINLIST_SUBSCRIPTION:
                avi = !s.checkInAvailability ? " ❌" : null;
                let contrattoNonFirmato = null;
                disabled = false;
                if (!s.agreementContract && isRequirementForSignatureContract) {
                  contrattoNonFirmato = " - Contratto non firmato";
                  disabled = true;
                }

                const numInvoices = !!s?.invoices ? s?.invoices.length : 0;
                let msgEmoj;
                switch (numInvoices) {
                  case 0:
                    msgEmoj =
                      "Non ci sono rate pagate per effettuare il CheckIn";
                    break;
                  case 1:
                    if (s.checkinCadence === constants.QUARTERLY) {
                      msgEmoj =
                        "Non ci sono abbastanza rata pagate per effettuare il CheckIn";
                    } else {
                      msgEmoj = "C'è una rata pagata per effettuare il CheckIn";
                    }
                    break;
                  case 2:
                    if (s.checkinCadence === constants.QUARTERLY) {
                      msgEmoj =
                        "Non ci sono abbastanza rata pagate per effettuare il CheckIn";
                    } else {
                      msgEmoj =
                        "Ci sono due rata pagate per effettuare il CheckIn";
                    }
                    break;
                  default:
                    msgEmoj = `Ci sono ${numInvoices} rata pagate per effettuare CheckIn`;
                }
                numRate = <Badge variant="secondary">{numInvoices}</Badge>;
                label = (
                  <EmojiSpan title={msgEmoj}>
                    <span>
                      {numRate} {status} {s?.startDate} {s.name} {avi}{" "}
                      {contrattoNonFirmato}
                    </span>
                  </EmojiSpan>
                );
                break;
              case constants.TYPE_CHECKINLIST_CREDIT:
                avi = !s.checkInAvailability ? " ❌" : null;
                disabled = s.numberPrest > 0 ? false : true;
                numRate = (
                  <Badge variant="secondary">{s.remainingCheckins}</Badge>
                );
                label = (
                  <span>
                    {numRate} {status} {s?.startDate} Credito:{" "}
                    {s?.creditTypeName} - {s?.name} {avi}
                  </span>
                );
                break;
              case constants.TYPE_CHECKINLIST_PREPAID_PACKAGE:
                avi = !s.checkInAvailability ? " ❌" : null;
                disabled = s.numberPrest > 0 ? false : true;
                numRate = (
                  <Badge variant="secondary">{s.remainingCheckins}</Badge>
                );
                label = (
                  <span>
                    {numRate} {status} {s?.startDate} - {s?.name} {avi}
                  </span>
                );
                break;
              default:
                label = s?.name;
            }
            if (
              ![
                constants.CHECKIN_FUNDING_CONSULTANT,
                constants.FOUNDING_SOURCE_FREE_TRIAL,
                constants.FOUNDING_SOURCE_GIFT,
              ].includes(s.uuid)
            )
              disabled = showTaxIdNotPresent;
            return (
              <Form.Check
                id={s.uuid}
                type="radio"
                key={s.uuid}
                value={s.uuid}
                label={label}
                disabled={disabled}
                {...register("typeCheckIns")}
                defaultChecked={s.checked === 1}
                onClick={() => setValue("productZones", s.zone)}
                onChange={(e) => {
                  handleChangeRadio(s);
                }}
              ></Form.Check>
            );
          })}
        </Form.Group>
      </>
    );
  }

  let btnPlusCheckIn = null;
  if (checkinList?.length !== 0) {
    btnPlusCheckIn = (
      <Button
        variant="outline-secondary"
        size="sm"
        as="a"
        onClick={openCustomerCheckinsList}
      >
        Tutti i Check-In ({counterCheckIn})
      </Button>
    );
  }
  //
  //load list check-ins
  let checkinListGroupItems = null;
  if (checkinList?.length === 0) {
    checkinListGroupItems = (
      <ListGroup.Item disabled className="list-group-item-ck">
        {" "}
        Non ci sono check-in effettuati
      </ListGroup.Item>
    );
  } else {
    checkinList = checkinList.slice(0, 3);
    checkinListGroupItems = (
      <>
        {checkinList.map((s) => {
          return (
            <ListGroup.Item
              key={s.uuid}
              value={s.index}
              className="list-group-item-ck"
              action
              href={getCheckinDetailUrl(s.uuid)}
            >
              {s.checkInStatus}
              {s.checkInDate.substr(2)} | {s.checkInPlanShortName} |{" "}
              {s.checkInProductName}
            </ListGroup.Item>
          );
        })}
      </>
    );
  }

  const alertCheckInAvailability = (
    <Alert variant="warning">
      <p>
        L'abbonamento ha esaurito il numero di Check-In disponibili nel periodo
      </p>
    </Alert>
  );

  const alertCheckInAvailabilityCredit = (
    <Alert variant="warning">
      <p>
        Il CREDITO ha esaurito il numero di Check-In disponibili nel periodo
      </p>
    </Alert>
  );
  const alertCheckInDuplicate = (
    <Alert variant="danger">
      <p>Oggi è stato già inserito un Check-In con lo stesso trattamento</p>
    </Alert>
  );

  const alertSubscriptionPresent = (
    <Alert variant="danger">
      <p>
        Per l'abbonamento prescelto è possibile effettuare solo un Check-In per
        'Non Presente'
      </p>
    </Alert>
  );

  const alertIssueInvoice = (
    <Alert variant="info">
      <p>
        E' un Accesso a Pagamento.{" "}
        <font size="+2">
          <b>La registrazione deve essere effettuata da Tablet.</b>
        </font>
        <br></br>
        Con la registrazione del Check-In verrà emessa contestualmente la
        ricevuta di pagamento.
      </p>
    </Alert>
  );

  const alertTaxIdNotPresent = (
    <Alert variant="info">
      <p>Il cliente non ha il Codice Fiscale</p>
    </Alert>
  );

  const isPresent = (
    <Form.Group as={Col} xs={4} controlId="present">
      <FormElement
        label="Presente"
        name="present"
        register={register}
        errors={errors}
        // controlOptions={{ as: "select" }}
        controlOptions={{
          as: "select",
          onChange: (e) => {
            return onChangePresent(e);
          },
        }}
      >
        <option key="1" value={true}>
          Si
        </option>
        <option key="0" value={false}>
          No
        </option>
      </FormElement>
    </Form.Group>
  );

  function calculateTotalPrice(
    productUuid = null,
    numZones = null,
    calcPrice = false
  ) {
    let priceTotal = 0;
    const zonesNum = !!numZones ? numZones : getValues("productZones");
    const uuidProduct = !!productUuid ? productUuid : getValues("product");
    const hasZones = !!mapProducts.find(
      (products) => products.uuid === uuidProduct && products.hasZones
    );

    let numMax = zonesNum;
    if (!hasZones) {
      numMax = 1;
      setValue("productZones", 1);
    }
    if (calcPrice) {
      const attualPrice = productsPrice.find(
        (priceForProduct) => priceForProduct.product.uuid === uuidProduct
      );

      if (!!attualPrice) {
        const price = (attualPrice.price * 1).toFixed(2);
        setValue("price", price);
        priceTotal = (price * numMax).toFixed(2);
        setValue("totalPrice", priceTotal);
      }
    }
    return priceTotal;
  }

  function onChangeProduct(event) {
    setMsgValue(null); //erase previus error
    setValue("productZones", 1);

    calculateTotalPrice(event.target.value, 1, showPrice);
    setChangeProduct(event.target.value);
    if (
      checkConsentLaserIsRequired(
        customer,
        mapProducts,
        event.target.value,
        "info"
      )
    )
      return;
  }

  function onChangePresent(event) {
    if (radioValue.radioUuid === constants.CHECKIN_FUNDING_PAID_ACCESS) {
      setMsgValue(null); //erase previus error
      const verifyPresent = event.currentTarget.value === "true" ? true : false;
      setShowPrice(verifyPresent);
      setShowAlertInvoice(verifyPresent);
    }
    return;
  }

  function onChangeProductZones(event) {
    setMsgValue(null); //erase previus error

    calculateTotalPrice(changeProduct, event.target.value, showPrice);
  }

  const btnRegistra = (
    <>
      <Button
        variant={isEmpty(errors) ? "primary" : "danger"}
        type="submit"
        disabled={creating || !enableSubmitCheckin}
      >
        {creating ? buttonSpinner : "Registra"}
      </Button>
    </>
  );

  function calculateInvoicePrice(productUuid = null, numZones = null) {
    let priceTotal = 0;
    const zonesNum = !!numZones ? numZones : getValues("productZones");
    const uuidProduct = !!productUuid ? productUuid : getValues("product");
    const hasZones = !!mapProducts.find(
      (products) => products.uuid === uuidProduct && products.hasZones
    );
    const productName = mapProducts.find(
      (products) => products.uuid === uuidProduct
    );

    let numMax = zonesNum;
    if (!hasZones) {
      numMax = 1;
    }
    if (showPrice) {
      const attualPrice = productsPrice.find(
        (priceForProduct) => priceForProduct.product.uuid === uuidProduct
      );

      if (!!attualPrice) {
        const price = (attualPrice.price * 1).toFixed(2);
        priceTotal = (price * numMax).toFixed(2);
      }
    }
    const dictResult = {
      hasZones: hasZones,
      zonesNum: numMax,
      productName: productName.name,
      priceTotal: priceTotal,
    };
    return dictResult;
  }

  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={7}>
            <h4 className="text-center"> Apertura Check In </h4>
          </Col>
          <Col xs={5} className="text-end">
            <ButtonToolbar className="float-end">
              <Row>
                <Col>{btnRegistra}</Col>
                <Col>
                  <DropdownButton
                    menualign="right"
                    title="Azioni"
                    id="dropdown-menu-align-right"
                    variant="outline-secondary"
                  >
                    <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                    <Dropdown.Item href={getCustomerUrl(customer?.uuid)}>
                      {addBsArrowRight("Cliente")}
                    </Dropdown.Item>
                  </DropdownButton>
                </Col>
              </Row>
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Nome: </h6>
            <span>{customer.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{customer.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{customer.email}</span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Form.Group as={Col} xs={6} controlId="typecheckin">
            <Form.Label>Tipo Check-In:</Form.Label>
            {radioSub}
          </Form.Group>

          <Form.Group as={Col} xs={6} controlId="CheckIn">
            <Row>
              <Col>
                <Form.Label>Ultimi Check-In effettuati</Form.Label>
              </Col>
              <Col>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  {btnPlusCheckIn}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ListGroup className="list-group-ck">
                  {checkinListGroupItems}
                </ListGroup>
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <hr />
        <Row>
          <Form.Group as={Col} xs={4} controlId="date">
            <FormElement
              label="Data Check-In"
              type="date"
              name="date"
              register={register}
              errors={errors}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={4} controlId="time">
            <FormElement
              label="Ora Check-In"
              type="time"
              name="time"
              controlOptions={{
                pattern: "[0-9]{2}:[0-9]{2}",
              }}
              register={register}
              errors={errors}
            ></FormElement>
          </Form.Group>
          {isPresent}
        </Row>
        <Row>
          <Form.Group as={Col} xs={6} controlId="worker">
            <FormElement
              label="Estetista"
              name="worker"
              register={register}
              errors={errors}
              controlOptions={{ as: "select" }}
            >
              {workers.map((w) => (
                <option key={w.uuid} value={w.uuid}>
                  {`${w.last} ${w.first}`}
                </option>
              ))}
            </FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={6} controlId="station">
            <FormElement
              label="Box"
              name="station"
              register={register}
              errors={errors}
              controlOptions={{ as: "select" }}
            >
              {stations.map((s) => (
                <option key={s.uuid} value={s.uuid}>
                  {s.name}
                </option>
              ))}
            </FormElement>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} xs={6} controlId="product">
            <FormElement
              label="Trattamenti"
              name="product"
              register={register}
              errors={errors}
              controlOptions={{
                as: "select",
                onChange: (e) => {
                  return onChangeProduct(e);
                },
              }}
            >
              {mapProducts.map((p) => (
                <option key={p.uuid} value={p.uuid}>
                  {p.name}
                </option>
              ))}
            </FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={2} controlId="productZones">
            <FormElement
              label="Numero Zone"
              type="number"
              name="productZones"
              register={register}
              errors={errors}
              controlOptions={{
                as: "select",
                onChange: (e) => {
                  return onChangeProductZones(e);
                },
              }}
            >
              {constNum.map((p) => (
                <option key={`pz${p}`} value={p}>
                  {p}
                </option>
              ))}
            </FormElement>
          </Form.Group>
          {showPrice ? groupShowingPrice : null}
        </Row>
        <Row>
          <Form.Group as={Col} controlId="notes">
            <FormElement
              label="Note"
              name="notes"
              register={register}
              errors={errors}
              controlOptions={{ rows: "3", as: "textarea" }}
            ></FormElement>
          </Form.Group>
        </Row>
        <Row>
          <ErrorMessage errors={[createError]} />
        </Row>
        <Row>{showInfo ? alertCheckInAvailability : null}</Row>
        <Row>{showInfoCredit ? alertCheckInAvailabilityCredit : null}</Row>
        <Row>{showCheckInDuplicate ? alertCheckInDuplicate : null}</Row>
        <Row>{showAlertInvoice ? alertIssueInvoice : null}</Row>
        <Row>{msgValue !== "" ? msgValue : null}</Row>
        <Row>{createSuccess !== "" ? createSuccess : null}</Row>
        <Row>{showPrivacyInfo ? alertNeedsPrivacy : null}</Row>
        <Row>{showSubscriptionPresent ? alertSubscriptionPresent : null}</Row>
        <Row>{showTaxIdNotPresent ? alertTaxIdNotPresent : null}</Row>
        <Row>
          <Col>
            <Form.Control
              type="number"
              name="mandatoryNotes"
              {...register("mandatoryNotes")}
              defaultValue="0"
              hidden
            ></Form.Control>
          </Col>
        </Row>
      </Form>

      <ContinueModal
        show={showModal}
        onContinue={() => {
          setShowModal({ show: false, checkinUuid: checkInUuid });
          modalContinueClicked();
        }}
      />
    </>
  );
}

export default CustomerCheckInForm;
