import "bootstrap/dist/css/bootstrap.min.css";

import * as Sentry from "@sentry/react";

import { Navigate, Route, Routes } from "react-router-dom";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ensureHTTPS, isDev } from "../api/utils";

import { ApolloProvider } from "@apollo/client";
import CheckInListPage from "./CheckInListPage";
import CheckInsListOpenPage from "./CheckInsListOpenPage";
import Container from "react-bootstrap/Container";
import CreditCheckInsListPage from "./CreditCheckInsListPage";
import CreditDetailPage from "./CreditDetailPage";
import CreditInsertPage from "./CreditInsertPage";
import CreditListPage from "./CreditListPage";
import CreditUpdatePage from "./CreditUpdatePage";
import CustomerCheckInDetailPage from "./CustomerCheckInDetailPage";
import CustomerCheckInPage from "./CustomerCheckInPage";
import CustomerCheckInUpdatePage from "./CustomerCheckInUpdatePage";
import CustomerCheckInsListPage from "./CustomerCheckInsListPage";
import CustomerDetailPage from "./CustomerDetailPage";
import CustomerListPage from "./CustomerListPage";
import CustomerSubscriptionsListPage from "./CustomerSubscriptionsListPage";
import CustomerUpdatePage from "./CustomerUpdatePage";
import { Helmet } from "react-helmet";
import InvoiceDetailPage from "./InvoiceDetailPage";
import LeadManagerPage from "./LeadManagerPage";
import LeadPage from "./LeadPage";
//import "./App.css";
import LoginPage from "./LoginPageV2";
import LogoutPage from "./LogoutPageV2";
import MainPage from "./MainPage";
import NavbarHeader from "../components/nav/Navbar";
import PlanDetailPage from "./PlanDetailPage";
import PlanListPage from "./PlanListPage";
import PrepaidPackageCheckInsListPage from "./PrepaidPackageCheckInsListPage";
import PrepaidPackageDetailPage from "./PrepaidPackageDetailPage";
import PrepaidPackageUpdatePage from "./PrepaidPackageUpdatePage";
import PrivateRoute from "../components/PrivateRoute";
import React from "react";
import ReceiptCreatePage from "../receipts/pages/ReceiptCreatePage";
import ReceiptDetailPage from "../receipts/pages/ReceiptDetailPage";
import ReceiptUpdatePage from "../receipts/pages/ReceiptUpdatePage";
import { BrowserRouter as Router } from "react-router-dom";
import { StateProvider } from "../store";
import SubscriptionCheckInsListPage from "./SubscriptionCheckInsListPage";
import SubscriptionCreatedPage from "./SubscriptionCreatedPage";
import SubscriptionDetailPage from "./SubscriptionDetailPage";
import SubscriptionInvoicesListPage from "./SubscriptionInvoicesListPage";
import { SubscriptionPage as SubscriptionPageV3 } from "./SubscriptionPageV3";
import SubscriptionUpdatePage from "./SubscriptionUpdatePage";
import SubscriptionsListPage from "./SubscriptionsListPage";
import SubscriptionsScheduledListPage from "./SubscriptionsScheduledListPage";
import SumUpCallBackPage from "./SumUpCallBackPage";
import UnpaidInvoicesListPage from "./UnpaidInvoicesListPage";
import UnpaidSubscriptionsListPage from "./UnpaidSubscriptionsListPage";
import { client } from "../api/apollo_client.js";
import constants from "../constants";
import urls from "../api/urls";
import { useEffect } from "react";

if (isDev()) {
  Sentry.init({
    dsn: constants.SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,
  });
}

function AppHelmet() {
  // This component adds all metatata to the Head of the HTML page
  return (
    <Helmet>
      <title>Medex-Pay</title>
      <meta name="description" content="App Description" />
    </Helmet>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route
        path={urls.URL_CHECKIN_LIST_OPEN}
        element={
          <PrivateRoute>
            <CheckInsListOpenPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CUSTOMER_CHECK_IN}
        element={
          <PrivateRoute>
            <CustomerCheckInPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_PLAN_DETAIL}
        element={
          <PrivateRoute>
            <PlanDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_LIST_PLAN}
        element={
          <PrivateRoute>
            <PlanListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CHECK_INS_UPDATE}
        element={
          <PrivateRoute>
            <CustomerCheckInUpdatePage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CHECK_INS_DETAIL}
        element={
          <PrivateRoute>
            <CustomerCheckInDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={`${urls.URL_SUBSCRIPTION_NEW_CONFIRMATION}/:guid`}
        element={
          <PrivateRoute>
            <SubscriptionCreatedPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTION_NEW_V3}
        element={
          <PrivateRoute>
            <SubscriptionPageV3 />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTION_NEW}
        element={
          <PrivateRoute>
            <SubscriptionPageV3 />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CUSTOMER_CHECK_INS_LIST}
        element={
          <PrivateRoute>
            <CustomerCheckInsListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CUSTOMER_SUBSCRIPTIONS_LIST}
        element={
          <PrivateRoute>
            <CustomerSubscriptionsListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTION_CHECK_INS_LIST}
        element={
          <PrivateRoute>
            <SubscriptionCheckInsListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTION_INVOICES_LIST}
        element={
          <PrivateRoute>
            <SubscriptionInvoicesListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_INVOICES_UNPAID}
        element={
          <PrivateRoute>
            <UnpaidInvoicesListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTIONS_UNPAID}
        element={
          <PrivateRoute>
            <UnpaidSubscriptionsListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTIONS_SCHEDULED}
        element={
          <PrivateRoute>
            <SubscriptionsScheduledListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTION_UPDATE}
        element={
          <PrivateRoute>
            <SubscriptionUpdatePage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUBSCRIPTION_DETAIL}
        element={
          <PrivateRoute>
            <SubscriptionDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CUSTOMER_UPDATE}
        element={
          <PrivateRoute>
            <CustomerUpdatePage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CUSTOMER_DETAIL}
        element={
          <PrivateRoute>
            <CustomerDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_LIST_CUSTOMERS}
        element={
          <PrivateRoute>
            <CustomerListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_LIST_SUBSCRIPTION}
        element={
          <PrivateRoute>
            <SubscriptionsListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_INVOICE_DETAIL}
        element={
          <PrivateRoute>
            <InvoiceDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CREDIT_DETAIL}
        element={
          <PrivateRoute>
            <CreditDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CREDIT_CHECK_INS_LIST}
        element={
          <PrivateRoute>
            <CreditCheckInsListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CREDIT_INSERT}
        element={
          <PrivateRoute>
            <CreditInsertPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CREDIT_UPDATE}
        element={
          <PrivateRoute>
            <CreditUpdatePage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_LIST_CREDIT}
        element={
          <PrivateRoute>
            <CreditListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_CREATE_RECEIPT}
        element={
          <PrivateRoute>
            <ReceiptCreatePage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_RECEIPT_UPDATE}
        element={
          <PrivateRoute>
            <ReceiptUpdatePage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SINGLE_LEAD}
        element={
          <PrivateRoute>
            <LeadPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_LEADS}
        element={
          <PrivateRoute>
            <LeadManagerPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_SUMUP_CALLBACK}
        element={
          <PrivateRoute>
            <SumUpCallBackPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_RECEIPT_DETAIL}
        element={
          <PrivateRoute>
            <ReceiptDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_LIST_CHECK_IN}
        element={
          <PrivateRoute>
            <CheckInListPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_PREPAID_PACKAGE_DETAIL}
        element={
          <PrivateRoute>
            <PrepaidPackageDetailPage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_PREPAID_PACKAGE_UPDATE}
        element={
          <PrivateRoute>
            <PrepaidPackageUpdatePage />
          </PrivateRoute>
        }
      />
      <Route
        path={urls.URL_PREPAID_PACKAGE_CHECK_INS_LIST}
        element={
          <PrivateRoute>
            <PrepaidPackageCheckInsListPage />
          </PrivateRoute>
        }
      />

      <Route path={urls.URL_LOGIN_PATH} element={<LoginPage />} />
      <Route path={urls.URL_LOGOUT_PATH} element={<LogoutPage />} />
      <Route
        path={urls.URL_SUBSCRIPTION_DETAIL_V1}
        element={<Navigate replace to={urls.URL_SUBSCRIPTION_DETAIL} />}
      />
      <Route
        path={urls.URL_CREDIT_DETAIL_V1}
        element={<Navigate replace to={urls.URL_CREDIT_DETAIL} />}
      />
      <Route
        path={urls.URL_RECEIPT_DETAIL_V1}
        element={<Navigate replace to={urls.URL_RECEIPT_DETAIL} />}
      />
      <Route path="/" element={<MainPage />} />
    </Routes>
  );
}

function App() {
  ensureHTTPS();

  const appComponents = (
    <>
      <AppHelmet />
      <NavbarHeader />
      <Container>
        <AppRoutes />
      </Container>
    </>
  );
  return (
    <StateProvider>
      <ApolloProvider client={client}>
        <Router>{appComponents}</Router>
      </ApolloProvider>
    </StateProvider>
  );
}

export default App;
